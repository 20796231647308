import React, { useCallback, useEffect, useRef, useState } from "react";
import { EmblaOptionsType } from "embla-carousel";
import { useEmblaCarousel } from "embla-carousel/react";
import deepmerge from "deepmerge";
import classnames from "classnames";
import useMouse from "@react-hook/mouse-position";
import useBreakpoint, { Breakpoint, BREAKPOINTS } from "@/utils/use-breakpoint";
import Button from "@/components/Button";
import ChevronLeft from "@/icons/chevron-left.svg";
import ChevronRight from "@/icons/chevron-right.svg";

type SlidePerPageConfiguration = Partial<Record<Breakpoint, number>>;

export interface Props extends EmblaOptionsType {
  children: React.ReactNode;
  slidesPerPage?: number | SlidePerPageConfiguration;
}

const defaultOptions: EmblaOptionsType = {
  // align: "start",
  inViewThreshold: 0.2,
  // containScroll: "trimSnaps",
};

export const slidesPerPageConfiguration: SlidePerPageConfiguration = {
  xs: 1,
  md: 2,
  lg: 3,
};

const calculateSlidesPerPage = (
  currentBreakpoint: Breakpoint,
  slidesPerPage: Props["slidesPerPage"]
): number | undefined => {
  if (typeof slidesPerPage === "number" || !slidesPerPage) {
    return slidesPerPage;
  }

  return (
    BREAKPOINTS.reduceRight((found, breakpoint, index) => {
      if (found) {
        return found;
      }

      if (index <= BREAKPOINTS.findIndex(brk => brk === currentBreakpoint)) {
        return slidesPerPage[breakpoint] || 0;
      }

      return 0;
    }, 0) || 1
  );
};

const Slide = ({ children, className }: { children: React.ReactNode; className?: string }) => {
  return <div className={classnames("relative", className)}>{children}</div>;
};

const directionToIconMap = {
  left: ChevronLeft,
  right: ChevronRight,
};

const CarouselButton = ({
  carouselElement,
  className,
  onClick,
  activeDirection,
}: {
  carouselElement: React.RefObject<HTMLDivElement>;
  className?: string;
  onClick: (direction: "left" | "right") => void;
  activeDirection: [boolean, boolean];
}) => {
  const mousePosition = useMouse(carouselElement);

  if (!mousePosition.isOver || !mousePosition.y || !carouselElement.current) {
    return null;
  }

  if (mousePosition.y < 0 || mousePosition.y > carouselElement.current.offsetHeight) {
    return null;
  }

  const mouseXPercentage = ((mousePosition.pageX || 0) * 100) / window.innerWidth;
  const buttonDirection = (mouseXPercentage < 15 && "left") || (mouseXPercentage > 85 && "right");
  const [isLeftActive, isRightActive] = activeDirection;

  if (
    !buttonDirection ||
    (buttonDirection === "left" && !isLeftActive) ||
    (buttonDirection === "right" && !isRightActive)
  ) {
    return null;
  }

  const Chevron = directionToIconMap[buttonDirection];

  return (
    <Button
      shape="round"
      rank="secondary"
      className={classnames("fixed z-10 opacity-0 hover:opacity-100 transition-opacity w-12 h-12", className)}
      onClick={() => onClick(buttonDirection)}
      style={{
        cursor: "none",
        transform: "translate(-50%, -50%)",
        top: `${mousePosition.clientY}px`,
        left: `${mousePosition.clientX}px`,
      }}
    >
      <Chevron />
    </Button>
  );
};

const Carousel = ({ children, slidesPerPage: initialSlidesPerPage, ...options }: Props) => {
  const [emblaRef, emblaApi] = useEmblaCarousel(deepmerge(defaultOptions, options));
  const [activeDirection, setActiveDirection] = useState<[boolean, boolean]>([false, false]);
  const carouselElement = useRef<HTMLDivElement>(null);
  const breakpoint = useBreakpoint();
  const slidesPerPage = calculateSlidesPerPage(breakpoint, initialSlidesPerPage);
  const handleOnClick = useCallback(
    (direction: "left" | "right") => {
      if (!emblaApi) {
        return;
      }

      if (direction === "left" && activeDirection[0]) {
        emblaApi.scrollPrev();
      }

      if (direction === "right" && activeDirection[1]) {
        emblaApi.scrollNext();
      }
    },
    [emblaApi, activeDirection]
  );

  const handleOnSelect = useCallback(() => {
    if (!emblaApi) {
      return;
    }
    setActiveDirection([emblaApi.canScrollPrev(), emblaApi.canScrollNext()]);
  }, [emblaApi]);

  useEffect(() => {
    if (!emblaApi) {
      return;
    }
    handleOnSelect();

    emblaApi.on("select", handleOnSelect);

    return () => {
      emblaApi.off("select", handleOnSelect);
    };
  }, [emblaApi, handleOnSelect]);

  return (
    <div ref={carouselElement}>
      {carouselElement.current && (
        <CarouselButton carouselElement={carouselElement} onClick={handleOnClick} activeDirection={activeDirection} />
      )}
      <div ref={emblaRef} className="overflow-hidden cursor-move">
        <div
          className="grid grid-flow-col gap-2 md:gap-14"
          style={{
            gridAutoColumns: slidesPerPage ? `${100 / slidesPerPage}%` : undefined,
          }}
        >
          {children}
        </div>
      </div>
    </div>
  );
};

Carousel.Slide = Slide;

export default Carousel;
