import React, { CSSProperties, useMemo } from "react";
import Link from "@/components/Link";
import classnames from "classnames";
import MicroCopy from "@/components/MicroCopy";
import Heading from "@/components/Heading";

export interface Props {
  title: React.ReactNode;
  subtitle?: React.ReactNode;
  image: React.ReactNode;
  description?: React.ReactNode;
  price?: React.ReactNode;
  link: string;
  className?: string;
  style?: CSSProperties;
  isOutOfStock?: boolean;
}

const Slim = ({ title, image, link, className, price, style, isOutOfStock }: Props) => {
  const imageNode = useMemo(() => {
    if (!React.isValidElement(image)) {
      return null;
    }

    return React.cloneElement(image, {
      objectFit: "cover",
    });
  }, [image]);

  return (
    <Link
      to={link}
      className={classnames("relative z-10 block group md:hover:shadow-xl transition-shadow bg-athens-gray", className)}
      style={style}
    >
      {isOutOfStock && (
        <span className="absolute p-1 text-black bg-white top-1 right-1">
          <MicroCopy path="product.available-for-pre-order">Available for pre-order</MicroCopy>
        </span>
      )}
      <div className="z-0 h-full">{imageNode}</div>
      <div className="absolute bottom-0 left-0 flex justify-between w-full p-5 uppercase bg-white md:opacity-0 group-hover:opacity-100 transition-opacity text-cod-gray bold">
        <span>{title}</span>
        {price && <span>{price}</span>}
      </div>
    </Link>
  );
};

const Relaxed = ({ title, subtitle, image, description, link, className, style, isOutOfStock }: Props) => {
  const imageNode = useMemo(() => {
    if (!React.isValidElement(image)) {
      return null;
    }

    return React.cloneElement(image, {
      objectFit: "contain",
    });
  }, [image]);

  return (
    <div className={classnames("flex flex-col items-center", className)} style={style}>
      <Heading subtitle={subtitle} className="items-center text-center text-cod-gray" level={3}>
        {title}
      </Heading>
      <div className="flex justify-center my-4">
        <Link
          to={link}
          className="mx-5 my-2 md:w-4/5 md:max-w-md"
          // @note: This solves an image issue. There are no tailwind class
          // for this.
          style={{ lineHeight: 0 }}
        >
          {imageNode}
        </Link>
      </div>
      <div className="text-center uppercase">{description}</div>
      <Link to={link} className="text-black link link--inline">
        {isOutOfStock ? (
          <MicroCopy path="product.pre-order">Pre-order now</MicroCopy>
        ) : (
          <MicroCopy path="product.details">Shop</MicroCopy>
        )}
      </Link>
    </div>
  );
};

const ProductOverview = ({
  layout,
  ...props
}: Props & {
  layout?: "default" | "slim";
}) => {
  if (layout === "slim") {
    return <Slim {...props} />;
  }

  return <Relaxed {...props} />;
};
export default ProductOverview;
