import React from "react";
import Link from "@/components/Link";
import { GatsbyImage, IGatsbyImageData } from "gatsby-plugin-image";
import Carousel from "@/components/Carousel";
import ProductOverview from "@/components/ProductOverview";
import { ProductFragment } from "@/fragments/product";
import MicroCopy from "../MicroCopy";

export type Product = {
  title: string;
  slug: string;
  shortDescription: string;
  image?: {
    altText?: string | null;
    gatsbyImageData?: IGatsbyImageData;
  };
  isAvailable?: boolean;
};

export const mapDataToProducts = (products?: ProductFragment[]): Product[] => {
  if (!products) {
    return [];
  }

  return products.map(({ title, variants, images, shortDescription }) => {
    return {
      title: title || "N/D",
      slug: variants?.[0]?.slug || "404",
      // @note: the second image is the dress.
      image: images?.[1] || images?.[0] || undefined,
      shortDescription: shortDescription || "N/D",
      isAvailable: variants?.some(variant => variant?.inventoryQuantity && variant?.inventoryQuantity > 0),
    };
  });
};
export interface Props {
  products: Product[];
}

const ProductReel = ({ products }: Props) => {
  return (
    <div>
      <Carousel>
        {products.map((product, key) => {
          return (
            <Carousel.Slide key={key}>
              <ProductOverview
                title={product.title}
                link={product.slug}
                layout="slim"
                className="my-20 product-slide"
                // @ts-expect-error Come up with a getImage that returns a placeholder image.
                image={<GatsbyImage image={product.image.gatsbyImageData} alt={product.title} />}
                isOutOfStock={!product.isAvailable}
              />
            </Carousel.Slide>
          );
        })}
      </Carousel>
      <div className="container text-right">
        <Link className="mb-20 link link--inline" to="/shop">
          <MicroCopy path="product.see-full-collection">See full collection.</MicroCopy>
        </Link>
      </div>
    </div>
  );
};

export default ProductReel;
