import React, { useMemo } from "react";
import { Container } from "@/components/Heading";
import ScrollAndFixed, { ScrollType } from "@/components/ScrollAndFixed";
import { animated } from "@react-spring/web";
import classnames from "classnames";

type Layout = "default" | "reverse";

export interface Props {
  image: React.ReactNode;
  children: React.ReactNode;
  layout?: Layout;
  imageScrollType?: ScrollType;
}

const Media = ({ image, children, layout = "default", imageScrollType = "fixed" }: Props) => {
  const imageNode = useMemo(() => {
    if (!React.isValidElement(image)) {
      return image;
    }

    return React.cloneElement(image, {
      className: classnames(image.props.className, "h-screen md:w-1/2", {
        "float-right": layout === "reverse",
      }),
      objectPosition: "top",
    });
  }, [image, layout]);

  return (
    <div className="container">
      <ScrollAndFixed type={imageScrollType} className="top-0 left-0 object-top md:absolute md:h-full md:w-full">
        {imageNode}
      </ScrollAndFixed>
      <div className="grid grid-cols-4 gap-8 md:gap-20">
        {layout === "default" && <div className="col-span-0 md:col-span-2"></div>}
        <animated.div className="flex flex-col justify-between p-10 col-span-4 md:col-span-2">
          <Container>{children}</Container>
        </animated.div>
      </div>
    </div>
  );
};

export default Media;
