import { useState, useEffect } from "react";
import throttle from "lodash.throttle";

export const BREAKPOINTS = ["xs", "sm", "md", "lg", "xl"] as const;

export type Breakpoint = (typeof BREAKPOINTS)[number];

const getDeviceConfig = (): Breakpoint => {
  if (typeof window === "undefined") {
    return BREAKPOINTS[4];
  }

  const width = window.innerWidth;

  if (width < 640) {
    return BREAKPOINTS[0];
  }

  if (width >= 640 && width < 768) {
    return BREAKPOINTS[1];
  }
  if (width >= 768 && width < 1024) {
    return BREAKPOINTS[2];
  }
  if (width >= 1024 && width < 1280) {
    return BREAKPOINTS[3];
  }

  return BREAKPOINTS[4];
};

const useBreakpoint = () => {
  const [breakpoint, set] = useState(() => getDeviceConfig());

  useEffect(() => {
    const calcInnerWidth = throttle(function () {
      set(getDeviceConfig());
    }, 200);

    window.addEventListener("resize", calcInnerWidth);

    return () => window.removeEventListener("resize", calcInnerWidth);
  }, []);

  return breakpoint;
};
export default useBreakpoint;
