import React, { useContext, useMemo, useRef } from "react";
import { useInView } from "react-hook-inview";
import classnames from "classnames";
import { useSpring, animated } from "react-spring";
import { ParallaxContext, SectionContext } from "@/components/Layout";
import { range } from "@/utils/interpolate";

export type ScrollType = "default" | "fixed";
export interface Props {
  children: React.ReactNode;
  className?: string;
  type?: ScrollType;
}
const ScrollAndFixed = ({ children, className, type = "default" }: Props) => {
  const [ref, inView] = useInView({
    threshold: 0.2,
  });
  const element = useRef<HTMLDivElement>(null);
  const value = useContext(ParallaxContext);
  const offset = useContext(SectionContext);
  const landscapeImageNode = useMemo(() => {
    if (!React.isValidElement(children)) {
      return children;
    }

    return React.cloneElement(children, { className: classnames(children.props.className, "h-full") });
  }, [children]);
  const { scale } = useSpring({ scale: range([offset, offset + 2], [1.2, 1], value) });
  const position = type === "default" && (element.current?.getBoundingClientRect().top || 1) > 0 ? "absolute" : "fixed";

  return (
    <div
      className={classnames(
        "relative transition-opacity duration-700 h-screen pointer-events-none overflow-hidden",
        {
          "opacity-0": !inView,
          "opacity-100": inView,
        },
        className
      )}
      ref={element}
    >
      <div
        className="absolute top-0 w-full h-full"
        ref={ref}
        style={{
          clip: "rect(0, auto, auto, 0)",
          clipPath: "inset(0 0 0 0)",
          transform: "prespective(0)",
        }}
      >
        <animated.div
          className="top-0 w-full h-screen origin-top"
          style={{
            transform: scale.to(x => `scale(${x})`),
            position,
          }}
        >
          {landscapeImageNode}
        </animated.div>
      </div>
    </div>
  );
};

export default ScrollAndFixed;
